'use client'

import Image from 'next/image'
import { useTheme, alpha } from '@mui/material/styles'
import { Grid, Container, Typography } from '@mui/material'

// Types
import type { TitleImageProps } from '@/types/components/atoms/title-image'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

const TitleImageAtom = (props: TitleImageProps) => {
	// Props
	const { title, image } = props

	// Variables
	const { t } = useTranslation()
	const theme = useTheme()

	return (
		<Grid
			position="relative"
			overflow="hidden"
			sx={{
				'&::after': {
					content: '""',
					width: '100%',
					height: '100%',
					position: 'absolute',
					top: 0,
					left: 0,
					...(!image && { backgroundImage: 'linear-gradient(180deg, #73beff 0%, #005baa 100%)' }),
					backgroundColor: alpha(theme.palette.grey[900], 0.3),
					zIndex: 2
				},
				'& picture': {
					display: 'inline-block',
					width: '100%',
					height: '100%',
					position: 'absolute',
					top: '50%',
					left: 0,
					transform: 'translateY(-50%)',
					zIndex: 1,
					'& img': {
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						objectPosition: 'center'
					}
				}
			}}
		>
			{image && (
				<picture>
					<img src={`/api/media/download${image}`} alt={title} />
				</picture>
			)}

			<Grid
				width="100%"
				height="100%"
				position="absolute"
				top={'50%'}
				left={0}
				zIndex={3}
				sx={{
					opacity: 0.5,
					transform: 'translateY(-50%)',
					'& img': {
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						objectPosition: 'center'
					}
				}}
			>
				<Image src="/patterns/05.png" width={1438} height={323} alt="pattern" />
			</Grid>

			<Container sx={{ position: 'relative', zIndex: 3 }}>
				<Typography variant="h1" pt={{ xs: 12, md: 24 }} pb={{ xs: 3, md: 6 }} fontSize={{ xs: '2em', md: '3em' }} fontWeight={800} color={theme.palette.common.white}>
					{t(title)}
				</Typography>
			</Container>
		</Grid>
	)
}

export default TitleImageAtom
