// Types
import type { TransTextRegexes, TransTextComponent } from '@/types/utils/trans-text'

const TransText = (str: string) => {
	// Variables
	let finalDescription: string = str || ''
	const components: TransTextComponent[] = []
	let hasPattern: boolean = false

	// Set attributes
	finalDescription = finalDescription
		.replaceAll('class=', 'className=')
		.replaceAll('&lt;table&gt;', '<table>')
		.replaceAll('&lt;/table&gt;', '</table>')
		.replaceAll('&lt;thead&gt;', '<thead>')
		.replaceAll('&lt;/thead&gt;', '</thead>')
		.replaceAll('&lt;tbody&gt;', '<tbody>')
		.replaceAll('&lt;/tbody&gt;', '</tbody>')
		.replaceAll('&lt;tr&gt;', '<tr>')
		.replaceAll('&lt;/tr&gt;', '</tr>')
		.replaceAll('&lt;th&gt;', '<th>')
		.replaceAll('&lt;/th&gt;', '</th>')
		.replaceAll('&lt;td&gt;', '<td>')
		.replaceAll('&lt;/td&gt;', '</td>')
		.replaceAll('&lt;icon&gt;', '<icon>')
		.replaceAll('&lt;/icon&gt;', '</icon>')
		.replaceAll('&lt;alert&gt;', '<alert>')
		.replaceAll('&lt;/alert&gt;', '</alert>')
		.replaceAll('&amp;', '&')
		.replaceAll('&nbsp;', ' ')

	// Check
	const checkRegexes: TransTextRegexes = {
		' ': new RegExp(' style="[^"]+"', 'g'),
		'<pattern>': new RegExp('<p[^>]+><span[^>]+>&lt;pattern&gt;</span></p>', 'g'),
		'</pattern>': new RegExp('<p[^>]+><span[^>]+>&lt;/pattern&gt;</span></p>', 'g'),
		'<contact>': new RegExp('<p[^>]+><span[^>]+>&lt;contact&gt;</span></p>', 'g'),
		'</contact>': new RegExp('<p[^>]+><span[^>]+>&lt;/contact&gt;</span></p>', 'g'),
		'<accordion>': new RegExp('<p[^>]+><span[^>]+>&lt;accordion&gt;</span></p>', 'g'),
		'</accordion>': new RegExp('<p[^>]+><span[^>]+>&lt;/accordion&gt;</span></p>', 'g'),
		'<box>': new RegExp('<p[^>]+><span[^>]+>&lt;box&gt;</span></p>', 'g'),
		'</box>': new RegExp('<p[^>]+><span[^>]+>&lt;/box&gt;</span></p>', 'g'),
		'<list>': new RegExp('<p[^>]+><span[^>]+>&lt;list&gt;</span></p>', 'g'),
		'</list>': new RegExp('<p[^>]+><span[^>]+>&lt;/list&gt;</span></p>', 'g'),
		'<jobs>': new RegExp('<p[^>]+><span[^>]+>&lt;jobs&gt;</span></p>', 'g'),
		'</jobs>': new RegExp('<p[^>]+><span[^>]+>&lt;/jobs&gt;</span></p>', 'g'),
		'<quote>': new RegExp('<p[^>]+><span[^>]+>&lt;quote&gt;</span></p>', 'g'),
		'</quote>': new RegExp('<p[^>]+><span[^>]+>&lt;/quote&gt;</span></p>', 'g'),
		'<timeline>': new RegExp('<p[^>]+><span[^>]+>&lt;timeline&gt;</span></p>', 'g'),
		'</timeline>': new RegExp('<p[^>]+><span[^>]+>&lt;/timeline&gt;</span></p>', 'g'),
		'<download>': new RegExp('<p[^>]+><span[^>]+>&lt;download&gt;</span></p>', 'g'),
		'</download>': new RegExp('<p[^>]+><span[^>]+>&lt;/download&gt;</span></p>', 'g'),
		'<description>': new RegExp('<p[^>]+><span[^>]+>&lt;description&gt;</span></p>', 'g'),
		'</description>': new RegExp('<p[^>]+><span[^>]+>&lt;/description&gt;</span></p>', 'g'),
		'<date>': new RegExp('<p[^>]+><span[^>]+>&lt;date&gt;</span></p>', 'g'),
		'</date>': new RegExp('<p[^>]+><span[^>]+>&lt;/date&gt;</span></p>', 'g'),
		'<department>': new RegExp('<p[^>]+><span[^>]+>&lt;department&gt;</span></p>', 'g'),
		'</department>': new RegExp('<p[^>]+><span[^>]+>&lt;/department&gt;</span></p>', 'g')
	}

	Object.keys(checkRegexes).map(key => {
		const regex = checkRegexes[key]

		if (regex.test(finalDescription)) {
			const result = finalDescription.match(regex)

			if (result && result.length > 0)
				result.map(item => {
					finalDescription = finalDescription.replaceAll(item, key)
				})
		}
	})

	// Remove extra span
	finalDescription = finalDescription
		.replaceAll(/<p [^>]+><span ><table><\/span><br>/g, '<grid><table>')
		.replaceAll('<span ></table></span></p>', '</table></grid>')
		.replaceAll('<span ><thead></span><br>', '<thead>')
		.replaceAll('<span ></thead></span><br>', '</thead>')
		.replaceAll('<span ><tbody></span><br>', '<tbody>')
		.replaceAll('<span ></tbody></span><br>', '</tbody>')
		.replaceAll('<span ><tr></span><br>', '<tr>')
		.replaceAll('<span ></tr></span><br>', '</tr>')
		.replaceAll('<span ><th>', '<th>')
		.replaceAll('</th></span><br>', '</th>')
		.replaceAll('<span ><td>', '<td>')
		.replaceAll('</td></span><br>', '</td>')

	// Find
	const findRegexes: TransTextRegexes = {
		pattern: new RegExp('<pattern>(.*?)(?=</pattern>)', 'g'),
		contact: new RegExp('<contact>(.*?)(?=</contact>)', 'g'),
		accordion: new RegExp('<accordion>(.*?)(?=</accordion>)', 'g'),
		box: new RegExp('<box>(.*?)(?=</box>)', 'g'),
		list: new RegExp('<list>(.*?)(?=</list>)', 'g'),
		jobs: new RegExp('<jobs>(.*?)(?=</jobs>)', 'g'),
		quote: new RegExp('<quote>(.*?)(?=</quote>)', 'g'),
		timeline: new RegExp('<timeline>(.*?)(?=</timeline>)', 'g'),
		download: new RegExp('<download>(.*?)</download>', 'g'),
		description: new RegExp('<description>(.*?)(?=</description>)', 'g'),
		date: new RegExp('<date>(.*?)(?=</date>)', 'g'),
		department: new RegExp('<department>(.*?)(?=</department>)', 'g')
	}

	Object.keys(findRegexes).map(key => {
		const regex = findRegexes[key]

		if (regex.test(finalDescription)) {
			let result = finalDescription.match(regex)

			if (result && result.length > 0)
				result.map(item => {
					finalDescription = finalDescription.replace(item, '')
					components.push({ type: key, description: item.replace(`<${key}>`, '') })

					if (key === 'pattern') hasPattern = true
				})
		}
	})

	// Remove extra div
	finalDescription = finalDescription
		.replaceAll('</contact>', '')
		.replaceAll('</accordion>', '')
		.replaceAll('</box>', '')
		.replaceAll('</list>', '')
		.replaceAll('</jobs>', '')
		.replaceAll('</quote>', '')
		.replaceAll('</timeline>', '')
		.replaceAll('</description>', '')
		.replaceAll('</date>', '')
		.replaceAll('</department>', '')

	return { text: finalDescription, components, hasPattern }
}

export default TransText
