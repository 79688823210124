'use client'

import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useTheme, alpha } from '@mui/material/styles'
import { Container, Grid } from '@mui/material'

// Types
import type { ArticleProps } from '@/types/components/molecules/article'

// Utils
import TransText from '@/utils/trans-text'

// Componenets
const JobsMolecule = dynamic(() => import('@/components/molecules/jobs'))
const TextAtom = dynamic(() => import('@/components/atoms/text'))
const QuoteAtom = dynamic(() => import('@/components/atoms/quote'))
const TimelineAtom = dynamic(() => import('@/components/atoms/timeline'))
const DescriptionAtom = dynamic(() => import('@/components/atoms/description'))
const ListAtom = dynamic(() => import('@/components/atoms/list'))
const BoxAtom = dynamic(() => import('@/components/atoms/box'))
const ContactAtom = dynamic(() => import('@/components/atoms/contact'))
const DownloadnAtom = dynamic(() => import('@/components/atoms/download'))
const AccordionAtom = dynamic(() => import('@/components/atoms/accordion'))

const ArticleMolecule = (props: ArticleProps) => {
	// Props
	const { title, image, description, maxWidth } = props

	// Variables
	const theme = useTheme()
	const { text, components, hasPattern } = TransText(description || '')

	return (
		<Grid
			width="100%"
			position="relative"
			sx={{
				...(hasPattern
					? {
							py: { xs: 4, md: 16 },
							overflow: 'hidden'
						}
					: {})
			}}
		>
			{hasPattern && (
				<>
					<Grid position="absolute" top={0} left={0} sx={{ opacity: 0.5, zIndex: 1 }}>
						<Image src="/patterns/03.png" width={570} height={622} alt="pattern" />
					</Grid>

					<Grid position="absolute" top={0} right={0} sx={{ opacity: 0.4, zIndex: 1 }}>
						<Image src="/patterns/04.png" width={808} height={1027} alt="pattern" />
					</Grid>
				</>
			)}

			{text && (
				<Container maxWidth={maxWidth} sx={{ position: 'relative', zIndex: 3 }}>
					<Grid
						container
						flexDirection="row-reverse"
						justifyContent="space-between"
						py={6}
						spacing={{ md: 2 }}
						sx={{
							position: 'relative',
							...(hasPattern
								? {
										px: { xs: 4, md: 8 },
										'&::before': {
											content: '""',
											width: { xs: '100%', md: '70%' },
											height: '100%',
											position: 'absolute',
											top: 0,
											left: 0,
											backgroundColor: theme.palette.mode == 'dark' ? alpha(theme.palette.secondary.dark, 0.8) : alpha('#c8d7ef', 0.5),
											borderRadius: 1,
											boxShadow: `0px 12px 40px 0px ${alpha(theme.palette.grey[900], 0.04)}`,
											zIndex: -1
										}
									}
								: {})
						}}
					>
						{image && (
							<Grid
								item
								xs={12}
								sm={5.5}
								md={4.5}
								mb={2}
								sx={{
									'& picture': {
										display: 'inline-block',
										width: '100%',
										height: 'auto',
										zIndex: 1,
										'& img': {
											width: '100%',
											height: '100%',
											objectFit: 'cover',
											objectPosition: 'center',
											borderRadius: 1.25,
											overflow: 'hidden'
										}
									}
								}}
							>
								<picture>
									<img src={`/api/media/download${image}`} alt={title} />
								</picture>
							</Grid>
						)}

						{text && !hasPattern && (
							<Grid item xs={12} sm={image ? 6 : 12}>
								<TextAtom>{text}</TextAtom>
							</Grid>
						)}

						{components.map((component, index) => {
							if (component.type === 'pattern')
								return (
									<Grid key={index} item xs={12} sm={image ? 6 : 12}>
										<TextAtom>{component.description}</TextAtom>
									</Grid>
								)
							else return null
						})}
					</Grid>
				</Container>
			)}

			{components.map((component, index) => {
				if (component.type === 'contact') return <ContactAtom key={index} description={component.description} maxWidth={maxWidth} />
				else if (component.type === 'accordion') return <AccordionAtom key={index} defaultExpanded={index === 0 ? true : false} description={component.description} />
				else if (component.type === 'box')
					return <BoxAtom key={index} direction={index % 2 === 1 ? 'row' : 'row-reverse'} description={component.description} maxWidth={maxWidth} />
				else if (component.type === 'list') return <ListAtom key={index} description={component.description} />
				else if (component.type === 'jobs') return <JobsMolecule key={index} description={component.description} showAll={true} maxWidth={maxWidth} />
				else if (component.type === 'quote') return <QuoteAtom key={index} description={component.description} />
				else if (component.type === 'timeline') return <TimelineAtom key={index} description={component.description} />
				else if (component.type === 'download') return <DownloadnAtom key={index} description={component.description} maxWidth={maxWidth} />
				else if (component.type === 'description') return <DescriptionAtom key={index} description={component.description} />
				else return null
			})}
		</Grid>
	)
}

export default ArticleMolecule
